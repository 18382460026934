<template>
    <div class="signin">
        <div class="left" :style="{'background-image': `url(${require(`@/assets/img/${language}.png`)})`}"></div>
        <div class="right">
            <img class="close" :src="require('@/assets/img/close.svg')" alt="close" @click="$router.push('/')">

            <div class="signin-container">
                <div class="upper">
                    <img :src="require('@/assets/img/sign_in_icon.svg')" alt="icon" class="icon">
                    <img :src="require('@/assets/img/logo.png')" alt="logo" class="logo">
                    <p class="title">{{ $t('sign_in') }}</p>
                    <p class="desc">{{ $t('sign_in_desc') }}</p>
                    <button class="classmade" @click="showClassmadeLogin = true">{{ $t('sign_with_classmade') }}</button>
                    
                    <div class="separator">
                        <div class="line"></div>
                        <p class="or">or</p>
                        <div class="line"></div>
                    </div>
                </div>

                <!-- <div class="mobile-upper">
                    <img :src="require('@/assets/img/logo.png')" alt="logo" class="logo">
                    <p class="title">{{ $t('sign_in') }}</p>
                    <p class="desc">{{ $t('sign_in_desc') }}</p>
                </div> -->

                <div class="lower">
                    <form @submit.prevent="signin()" class="signin-form">
                        <p class="_error" v-if="error">{{ error[0] }}</p>

                        <input
                            type="text"
                            :placeholder="$t('email_address_or_username')"
                            v-model="email"
                        />

                        <div class="password-wrapper">
                            <input
                                class="password"
                                :type="isShow ? 'text' : 'password'"
                                :placeholder="$t('password')"
                                v-model="password"
                            />

                            <img 
                                v-if="isShow"
                                :src="require('@/assets/img/icon_hide.png')"
                                alt="show"
                                @click="isShow = !isShow"
                            >
                            <img
                                v-else
                                :src="require('@/assets/img/icon_show.png')"
                                alt="hide"
                                @click="isShow = !isShow"
                            >
                        </div>

                        <router-link to="/forgot-password" class="forgot">{{ $t('forgot_password') }}</router-link>

                        <button type="submit" class="submit" :disabled="loading">{{ $t('sign_in') }}</button>
                    </form>
                </div>
            </div>

            <div class="footer">
                <router-link to="/forgot-password" class="forgot">{{ $t('forgot_password') }}</router-link>

                <div class="sign-up-wrapper">
                    <p>{{ $t('dont_have_account') }}</p>
                    <router-link to="/sign-up" class="signup">{{ $t('join') }}</router-link>
                </div>
            </div>
        </div>

        <div class="modal-container" v-if="showClassmadeLogin">
            <div class="modal">
                <img class="classmade-logo" :src="require('@/assets/img/classmade-logo.png')" alt="classmade logo">
                <img 
                    class="close" 
                    :src="require('@/assets/img/close.svg')" 
                    alt="close"
                    @click="showClassmadeLogin = false"
                >
                <div class="title-wrapper">
                    <p>Your</p>
                    <p class="orange">Passion</p>
                    <p>Project</p>
                </div>

                <p class="desc">
                    Discover your interests, find your purpose, and connect with the rest of 
                    the world by starting your very own passion project with Classmade.
                </p>

                <form @submit.prevent="signInWithClassmade()" class="classmade-form">
                    <p class="label">Email Address</p>
                    <input type="email" v-model="classmadeEmail" required>

                    <p class="label">Password</p>
                    <input type="password" v-model="classmadePassword" required>
                    <p class="_error" v-if="classmadeError.password">{{ classmadeError.password[0] }}</p>

                    <p class="terms">By signing in, you agree to our Terms of Use.</p>

                    <button type="submit" class="submit" :disabled="loading">{{ $t('sign_in') }}</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    data () {
        return {
            isShow: false,
            email: '',
            password: '',
            showClassmadeLogin: false,

            classmadeEmail: '',
            classmadePassword: '',
            classmadeError: {}
        }
    },

    created () {
        this.$store.commit('SET_SIGN_IN_ERROR', {})
    },

    computed: {
        ...mapState({
            error: state => state.signInError,
            loading: state => state.loading,
            language: state => state.language
        })
    },

    methods: {
        signin () {
            let form = new FormData()

			form.append('email', this.email)
			form.append('password', this.password)
			this.$store.dispatch('signIn', form)
        },

        async signInWithClassmade () {
            let form = new FormData()
            form.append('email', this.classmadeEmail)
            form.append('password', this.classmadePassword)
            let obj = {
                method: 'POST',
                body: form,
                headers: {
                    'Accept': 'application/json'
                }
            }
            // let classmadeCall = await fetch(process.env.VUE_APP_CLASSMADE_URL + '/web/authenticate/login', obj)

            fetch(process.env.VUE_APP_CLASSMADE_URL + '/web/authenticate/login', obj)
            .then(response => response.json())
            .then(data => {
                let user = data.data
                if (data.status === 422) this.classmadeError = data.errors
                if (data.status === 200) {
                    let form = new FormData()
                    form.append('email', user.email)
                    form.append('classmade_user_id', user.id)
                    form.append('name', user.full_name)
                    form.append('organization', user.school)
                    form.append('organization_code', user.code)

                    this.$store.dispatch('signInWithClassmade', form)
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.signin {
    display: flex;

    .left {
        @include desktop {
            flex: 1;
            height: 100vh;
            // background: #47435c url('~@/assets/img/sign_placeholder.jpg') no-repeat center top;
            background-repeat: no-repeat;
            background-size: cover;
            // opacity: 0.8;
            border-radius: 0 4px 4px 0;
        }
    }

    .right {
        display: flex;
        flex-direction: column;
        flex: 1;
        position: relative;

        .close {
            cursor: pointer;
            position: absolute;
            top: 30px;
            right: 30px;

            width: 15px;
            height: 15px;

            @include desktop {
                width: unset;
                height: unset;
            }
        }

        .signin-container {
            padding: 0 20px;
            margin: 70px auto 0px;

            @include desktop {
                margin: auto;
                width: 425px;
            }

            .upper {
                margin-bottom: 20px;

                .icon {
                    display: none;

                    @include desktop {
                        display: block;
                    }
                }

                .logo {
                    display: block;
                    width: 150px;
                    height: 100px;
                    margin: auto;

                    @include desktop {
                        display: none;
                    }
                }

                .title {
                    font-family: 'Crimson Pro', sans-serif;
                    font-weight: 600;
                    font-size: 24px;
                    margin-bottom: 12px;
                }

                .desc {
                    color: #B8B8B8;
                    font-size: 14px;
                    margin-bottom: 40px;
                }
                
                .classmade {
                    padding: 15px;
                    width: 100%;
                    font-family: 'Crimson Pro', sans-serif;
                    font-weight: 700;
                    font-size: 20px;
                    background-color: #45B171;
                    border-radius: 5px;
                    color: #FFFFFF;
                    margin-bottom: 20px;
                }

                .separator {
                    padding: 0 15px;
                    display: flex;
                    align-items: center;
                    text-align: center;

                    .line {
                        height: 0px;
                        width: 40%;
                        border-top: 1px solid #B8B8B8;
                    }

                    .or {
                        width: 20%;
                        font-family: 'Crimson Pro', sans-serif;
                        font-size: 18px;
                        color: #B8B8B8;
                    }
                }
            }

            .lower {
                display: flex;
                flex-direction: column;

                .password-wrapper {
                    width: 100%;
                    position: relative;

                    @include desktop {
                        // margin-bottom: 5px;
                    }

                    .password {
                        padding-right: 55px;
                    }

                    img {
                        position: absolute;
                        right: 20px;
                        width: 24px;
                        height: 24px;
                        top: 18px;
                    }
                }

                ._error {
                    margin-bottom: 15px;
                    font-size: 14px;
                    text-align: center;
                }

                input {
                    width: 100%;
                    border-radius: 4px;
                    border: 1px solid #000000;
                    padding: 20px 15px;
                    font-size: 14px;
                    color: #00000099;
                    margin-bottom: 16px;
                }

                .submit {
                    width: 100%;
                    background-color: #00AEEF;
                    color: #FFFFFF;
                    font-weight: 600;
                    font-size: 16px;
                    padding: 18px;

                    &:disabled {
                        opacity: 0.7;
                        pointer-events: none;
                    }
                }

                .forgot {
                    display: inline-block;
                    width: 100%;
                    text-align: right;
                    color: #00AEEF;
                    font-size: 16px;
                    margin-bottom: 60px;

                    @include desktop {
                        display: none;
                    }
                }
            }
        }

        .footer {
            margin: 0 auto;
            display: flex;
            padding-bottom: 30px;
            align-items: center;
            padding: 30px 20px;

            @include desktop {
                width: 425px;
            }
            
            .forgot {
                flex: 1;
                color: #00AEEF;
                font-size: 16px;
                display: none;

                @include desktop {
                    display: flex;
                }
            }

            .sign-up-wrapper {
                display: flex;
                margin: auto;

                @include desktop {
                    flex: 1;
                    justify-content: flex-end;
                }

                p {
                    font-size: 16px;
                    margin-right: 3px;
                }

                .signup {
                    color: #00AEEF;
                    font-size: 16px;
                }
            }
        }
    }

    .modal-container {
        position: fixed;
        width: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: #000000a1;
        z-index: 999999;
        overflow-y: auto;
        display: flex;
        justify-content: center;
        align-items: center;

        .modal {
            position: relative;
            z-index: 999;
            padding : 50px;
            box-shadow: 0px 3px 6px #00000047;
            display: flex;
            flex-direction: column;
            text-align: left;
            background-color: #ffffff;
            border-radius: 20px;
            color: #7E7C7C;

            .classmade-logo {
                width: 216px;
                height: 48px;
                margin-bottom: 20px;
            }
    
            .title-wrapper {
                display: flex;
                margin-bottom: 20px;

                p {
                    font-family: 'Roboto', sans-serif;
                    color: #7E7C7C;
                    font-weight: 600;
                    font-size: 36px;
                    margin-right: 6px;

                    &.orange {
                        color: #F18448;
                    }
                }
            }
    
            .desc {
                font-family: 'Work Sans', sans-serif;
                font-weight: 400;
                width: 530px;
                margin-bottom: 30px;
                font-size: 16px;
            }

            .classmade-form {
                display: flex;
                flex-direction: column;
                
                .terms {
                    font-family: 'Work Sans', sans-serif;
                    color: #7E7C7C;
                    margin-bottom: 20px;
                }
    
                .label {
                    font-family: 'Work Sans', sans-serif;
                    margin-bottom: 10px;
                }
    
                input {
                    width: 100%;
                    border-radius: 4px;
                    border: 1px solid #C6CBD4;
                    padding: 12px 15px;
                    font-size: 16px;
                    color: #7E7C7C;
                    margin-bottom: 20px;
                }
    
                button {
                    width: 124px;
                    border: 1px solid #F18448;
                    color: #F18448;
                    background-color: #ffffff;
                    font-size: 14px;
                    font-family: 'Work Sans', sans-serif;
                    border-radius: 20px;
                    margin-left: auto;
                }

                ._error {
                    font-size: 16px;
                    margin-bottom: 15px;
                }
            }

    
            .close {
                cursor: pointer;
                position: absolute;
                top: 30px;
                right: 30px;
            }

        }
    }
}
</style>